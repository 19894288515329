.home_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.home_links_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.buy_button_link {
  text-decoration: none;
  cursor: pointer;
}

.home_title {
  font-size: 2rem;
  text-align: center;
}

.cows_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 3rem;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;
}

.cow_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cow_container_text {
  font-size: 1rem;
  text-align: center;
  width: 15rem;
}

.bottom_farm_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  /* background-color: black;
  color: white; */
}

@media (max-width: 1300px) {
  .cows_container {
    gap: 2rem;
  }
  .cow_container_text {
    font-size: 1.1rem;
  }
}
@media (max-width: 650px) {
  .bottom_farm_container {
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  .bottom_farm_container {
    font-size: 9px;
  }
}

@media (max-width: 550px) {
  .bottom_farm_container {
    font-size: 8px;
  }
}

@media (max-width: 500px) {
  .bottom_farm_container {
    font-size: 7px;
  }
}

@media (max-width: 440px) {
  .bottom_farm_container {
    font-size: 6.5px;
  }
}

@media (max-width: 400px) {
  .bottom_farm_container {
    font-size: 6px;
  }
}

@media (max-width: 360px) {
  .bottom_farm_container {
    font-size: 5.5px;
  }
}

@media (max-width: 330px) {
  .bottom_farm_container {
    font-size: 5px;
  }
}

@media (max-width: 310px) {
  .bottom_farm_container {
    font-size: 4.5px;
  }
}

@media (max-width: 270px) {
  .bottom_farm_container {
    font-size: 4px;
  }
}

@media (max-width: 230px) {
  .bottom_farm_container {
    font-size: 3.5px;
  }
}