body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  font-size: 16px;
}

@media (max-width: 1700px) {
  html {
    font-size: 15px;
  }
} 

@media (max-width: 1600px) {
  html {
    font-size: 14px;
  }
} 

@media (max-width: 1500px) {
  html {
    font-size: 13.5px;
  }
} 

@media (max-width: 1400px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1300px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 900px) {
  html {
    font-size: 11px;
  }
}

@media (max-width: 700px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 9px;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 7px;
  }
}

/* @media (max-width: 1200px) {
  html {
    font-size: 11px;
  }
} */